html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.container {
    padding: 0;
}

.suit-red {
    color: red;
}

.suit-black {
    color: black;
}

.playing-card {
    white-space: nowrap;
    font-size: 1.8em;
    padding: 0 0.5em;
}

.playing-card i {
    float: right;
    width: 1.5em;
    text-align: center;
    line-height: 1.5em;
    cursor: pointer;
}

.playing-card i.edit {
    background-color: #50596c;
    width: 1.5em;
    border-radius: 0.75em;
    color: white;
    text-align: center;
}

.card-done button span {
    margin-left: 5px;
    line-height: 1.5em;
}

.navbar {
    background-color: #e9ff5e;
    color: black;
}

.navbar h1 {
    font-size: 1.5em;
    margin: 0.5em 0 0.5em 0;
}

.navbar-icon {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.navbar-icon a {
    color: black;
    display: none;
}

.navbar-icon i.unavailable {
    display: none;
}

.navbar-icon-right {
    text-align: right;
}

.navbar .help-link {
    margin-right: 0.5em;
}

.cards {
    margin-top: 1em;
    padding-bottom: 1vw;
    border-bottom: 1px solid silver;
}

.controls {
}

.timer {
    text-align: center;
    font-size: 1.5em;
    margin: 0;
    padding: 0;
}

.drawn-cards {
    text-align: center;
}

.drawn-cards img {
    width: 50%;
    margin-right: 1%;
    margin-left: -30%;
    transition: margin-left 0.5s ease-in-out;
}

.drawn-cards .card-done {
    margin-top: 40px;
    position: relative;
    text-align: center;
}

.drawn-cards .card-done img {
    border-radius: 25px;
    width: 70%;
    margin-left: 0;
    margin-bottom: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

.drawn-cards .card-done > span {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    font-size: 3.5em;
    text-shadow: 2px 2px black;
    font-weight: bold;
}

.drawn-cards .card-done button {
    position: absolute;
    padding: 0 10px;
    top: 75%;
    left: 50%;
    transform: translate(-50%, 0);
    border: none;
    background-color: white;
    box-shadow: 4px 4px black;
    color: black;
    font-weight: normal;
    font-size: 1.5em;
}

.drawn-cards .card-done button img {
  width: 16px;
  height: 16px;
}

.drawn-cards img:first-child {
    margin-left: 0;
}

.drawn-cards img:last-child {
    margin-right: 0;
}

.draw-one img {
    width: 50%;
    margin: 0;
}

.first-joker img:first-child {
    margin-bottom: 12%;
    margin-right: -20%;
}

.progress-bar {
    width: 100%;
    height: 10px;
    display: block;
    clear: both;
}

.progress-progress {
    height: 10px;
    width: 0%;
    /* background-color: #ffe52b; */
    background-color: #70e224;
    float: left;
    transition: width 0.5s ease-out;
}

.progress-last {
    float: left;
    background-color: #70e224;
    transition: width 0.3s ease-out;
}

.progress-next {
    animation: animateGradient 1s linear infinite;
    background-color: white;
    /* border: 1px solid #70e224; */
    /* background-color: #ffe52b; */
    background-image: linear-gradient(90deg, #DBC30D, #FFF6B5, #DBC30D, #FFF6B5, #DBC30D);
    background-size: 200% 100%;
    transition: width 0.3s ease-out;
    border-left: 1px solid #FFF6B5;
    border-top: 1px solid #FFF6B5;
    border-bottom: 1px solid #DBC30D;
    border-right: 1px solid #DBC30D;
}

@keyframes animateGradient {
    0% { background-position: 100% 0%; }
    100% { background-position: 0% 0%; }
}

.hide-link {
    display: none;
}

.navbar-icon a.show-link {
    display: inline;
}

.form-radio {
    display: inline-block;
}

.form-group a {
    color: gray;
    position: relative;
    bottom: -0.4em;
    line-height: 1rem;
    display: inline-block;
}

.form-group span.exrx {
}

#exercise-selector .form-label {
    font-weight: bold;
}

.padbox {
    padding: 1em;
}

#toast {
  margin-bottom: 1em;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0.5em 1em;
  background-color: #101088;
  color: white;
  border-radius: 10px;
  animation: fadeIn ease-in-out 0.2s;
}

.toast-off {
  display: none;
  animation: fadeIn ease-in-out 0.2s;
}

.toast-on {
  display: block;
  animation: fadeIn ease-in-out 0.2s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

#version {
  position: fixed;
  bottom: 0;
  padding: 0 5px;
  color: silver;
}
